#layout-default {

    main {
        @include make-container-r();
        padding-top: 15px;
        min-height: 200px;

        .site-title{
            text-align: center;
            margin: 4* $spacer 0;

            h1{
                font-size: 3rem;
                font-weight: 300;
            }

            h2{
                font-weight: 300;
            }
        }
    }


    #search-list-boxes {
        margin-bottom: $spacer * 8;

        h1{
            font-size: 2.5rem;
            font-weight: 300;
            margin-top: 5*$spacer;
            margin-bottom: 3*$spacer;
            text-align: center;
        }

        h2{
            font-size: 2rem;
            margin-bottom: $spacer*2;
        }

        .info-search-box {
            @include make-r-col(12,12,8);

            padding: 3*$spacer;
            background-color: $dove-gray;
            color: $white;
            position: relative;
            left: 2*$spacer;

            .list-wrapper{
                column-count: 2;
            }

            h2{
                color: $white;
            }
        }

        .age-search-box {
            @include make-r-col(12,12,4);

            padding: 3*$spacer;
            color: $tmpltt_font-color-content;
            background-color: $st-yellow;

            position: relative;
            top: 3*$spacer;
            left: -4*$spacer;

            &::before{
                content:' ';
                display: block;
                position: absolute;
                width: 40px;
                height: 40px;
                background: $st-yellow;
                top: calc(50% - 20px);
                left: -20px;
                transform: scaleY(0.6) rotate(45deg);
            }
        }

        .search-list-box {
            span.link {
                text-decoration: none;
            }
        }

    }
}