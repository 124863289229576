
// common.DropDown

//.dropdown-menu.show {
//  display: block;
//  margin: -1px;
//  border-top: 0px;
//  color: $black;
//}
.dropdown-item{
  border-radius: 0;
  background-color: transparent;
  //weil border-left vom Button props übernommen wird, hier ausschalten
  border: none !important;
  color: $black;
  font-size: 1.2rem;
  &:hover{
    background-color: #f2f2f2;
    color: $black;
  }
}
.dropdown-item.active, .dropdown-item:active{
  background-color: $pampas;
  color: $black;
}
.dropdown-item.active:hover::before{
    content:"\f00d  ";
    font-family: 'Font Awesome 5 Free';
    font-weight:600;
  }
.dropdown-item.active::before, .dropdown-item:active::before{
  content:"\f00c  ";
  font-family: 'Font Awesome 5 Free';
  font-weight:600;
}
