//*********************************
// Font import
//*********************************
//https://developers.google.com/fonts/docs/getting_started
@import url('https://fonts.googleapis.com/css2?family=Merriweather+Sans:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap');

//*********************************
//STUTTGART
//*********************************

//use css variable to allow contrast change with change of body class
//:root{
//	--blau: #008AC2;
//
//	body.high-contrast{
//		--blau: #005F86;
//	}
//}
//$thue_blau:	var(--blau);

$white:    	    #fff;
$black:         #000;
$gray: 			#999;
$gray_dark:     #aaa;
$trans-white:   rgba(255,255,255, 0.6);

//Grundfarben Stuttgart
$st-yellow:         #FFB300;
$white:             #FFFFFF;
$pampas:            #F5F4F1;
$pampas-darker:     #EFEDE8;
$carrara:           #EAE8E3;
$silver-chalice:    #A7A7A7;
$silver-chalice-bright: #AEAEAE;
$dove-gray:         #727272;
$dove-gray-darker:  #646464;
$tundora:           #4B4B4B;
$mine-shaft-bright: #3B3B3B;
$mine-shaft:        #323131;
$mine-shaft-darker: #212020;
$dusty-gray:        #949494;

$st-text:           #26262C;

//Grundfarben
$thue_highlight-light:	rgba(0, 138, 194, 0.1);
$thue_red:       #dc1e16;


//overwrite bootstrap gutter width
//$grid-gutter-width: 20px;

//*********************************
//Variablen Layout [tmpltt]
//*********************************

//Schatten
$tmpltt_shadow: 1px 3px 5px #ccc;

//Farbkonzept SuchErgebnisse -> Text und Boxen -> SearchCardTitel
$tmpltt_font-color-results:   $st-text;
$tmpltt_font-color-results-linktext: $st-text;
$tmpltt_font-color-results_contrast: $st-text;
$tmpltt_background_accessibility: transparent;
$tmpltt_color_accessibility: $st-text;

//Pagination
$tmpltt_pagination_background: transparent;
$tmpltt_pagination_disabled: #ddd;

//Detailansicht Schriftfarben
//Überschriften (Header, Themensuche, Zielgruppensuche, Detailansicht Überschriften H1)
$tmpltt_font-color-highlight:   $black;
//Detailansichten Hintergrundfarbe je gerade Zeile
$tmpltt_font-color-highlight-light: $thue-highlight-light;

//Hintergrundfarbe
$tmpltt_layout_color-general: $white;
$tmpltt_layout_color-highlight: $st-yellow;

//Hintergrunffarbe Kacheln
$tmpltt_layout_color_kachel:  $white;

//Footer Boxfarbe
$tmpltt_layout_color-partial: $white;

//Menüfarbe
$tmpltt_layout_color-menu:		$st-yellow;

//BoxfarbeHintergrund Hinweistexte (z.B. keine Ergebnisse gefunden)
$tmpltt_layout_color-message: $tmpltt_layout_color-menu;

//Letzter Footer Boxfarbe (copytight, Impressum,...)
$tmpltt_layout_color-footer: $mine-shaft;

//Footer linke Boxfarbe (Teilnehmende Kommunen...)
$tmpltt_layout_color-footer-box-left: $white;

//Footer Rechte Boxfarbe(gefördert von)
$tmpltt_layout_color-footer-box-right: $white;

//Footer Trennlinie
$tmpltt_layout_color-footer-ruler: $st-yellow;


//Header (zoom, druck, usw.)
$tmpltt_font-color-menufunctions:  $gray_dark;
//Header (burgermenu)
$tmpltt_font-color-menuburger:     $st-text;
//Schriftfarben Inhalte und Menüs
$tmpltt_font-color-menu:         $st-text;
$tmpltt_font-color-title:        $black;
$tmpltt_font-color-content:      $st-text;
$tmpltt_font-color-input-placeholder: $gray;
$tmpltt_font-color-content-versus:$tmpltt_font-color-menu;

//SchriftArt
$tmpltt_font-family-general:    'Merriweather Sans', 'Lato', 'Helvetica', sans-serif;

//Schrift und Icon Farbe Suchleiste
$tmpltt_bg-color-searchmask: $st-yellow;
$tmpltt_bg-color-searchmask-extended: $white;
$tmpltt_font-color-searchmask:  $st-text;

//Zeilenhöhe
$tmpltt_lineheight: 1.6;

//Schriftfarbe Topic and Audince (Bodytext ansteuern)
$tmpltt_font-color-results_body_text: $tmpltt_font-color-results;


//Schriftfarbe für Angebote in Kachelresults, die zu den 'besonderen' Organisationen gehören, z.B. FGIF-Landkreis Hierarchie
$tmplt_font-color-results_spezialprovideroffer: $gray;

//*********************************
//*********************************
//Variablen für beide Templates [tmpltsh]
//Marker -ToDo scss:-
//*********************************

//Ladesymbol Farbe
$tmplsh_loader_color: $st-yellow;

//Seitenhintergrund Overlay Farbe
$tmplsh_loader_layout_color: $black ;

//Größe
$tmplsh_loader_size: 200;
//the :export directive is the magic sauce for webpack
:export {
  loader_color: $tmplsh_loader_color;
  loader_size: $tmplsh_loader_size;
  iconsSvg_color: $tmpltt_font-color-results;
  iconSvgNeu_color: $thue_red;
}
$tmpltsh_layout_color_message: $tmpltt_layout_color-message;
$tmpltsh_font_color_message: $tmpltt_font-color-content-versus;

//Preview vor Backend
$tmpltsh_font-color-watermark: rgba(0, 0, 0, 0.2);
$tmpltsh_font-color-ffo-gmap-content: $black;

//*********************************
// entry point for all basis scss logic
//*********************************
@import './sites/stuttgart/scss/App.scss';

//*********************************
// Debug
//*********************************

// Set the variable to false only if it's not already set.
$debug-scss: false !default; //true; //

// Show cureent responsive viewport
// @include debug-r();
