.cGmapWindowDetailPartialContainer {
  //display wird aus der Komponente gesteuert
  //display: none;
  z-index: 10; //sonst ist es hinter der Gmap versteckt

	position: absolute;
  min-width: 200px;
	box-shadow: 2px 2px 5px #ccc;
	background-color: white;

  //vom famponie übernommen
	// border-style: solid ;
	// border-width: 1.5rem 0 0 0;
  // top: calc(2rem + 8rem);
  // left: 2rem;
  .cGampWindowDetailPartial{
    @include pp(4);
  }
}

.gmap_window_detail_close {
	position: absolute;
  //font-size: 2rem;
	top: 1rem;
	right: 1rem;
	cursor: pointer;
}


.cGmapMarkerInfoWindow{
  background: white;
  padding: 10px;

  a.link{
    border-bottom: 1px dashed;
    cursor: pointer;
  }
}

// ToDo:  zerstört Design in local ausführung
//diese Klassen sind von GoogleDom abgeguckt und überschrieben
//Dieser Style produziert einen sehr merkwürdigen Bug bei Thüringen:
//Bei Klick auf Details eines Angebots, geht das komplette Stylesheet verloren.
//Es hat irgendwas mit dem Klassenname ".gm-" zu tun.

// .gm-style .gm-style-iw-c{  
//  border-radius: 0px !important;
// }


.cGmapMarkerInfoWindowDetails{
	@include mr(4);
}
