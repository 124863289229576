.wrapper-static{

    .img-subtitle{
        margin-top: 1rem !important;
        font-weight: 600;
    }


    .content-groups{
        header{
            max-width: 584px;
        }

        p{
            margin: 2*$spacer 0;
        }

        .group{
            border-top: 2px solid $carrara;
            margin-top: 6*$spacer;
            margin-bottom: 6*$spacer;
            padding-top: 2*$spacer;

            .intro{
                font-weight: 600;
            }
        }
    }

    .img-wrapper{
        display: block;
        margin: 4*$spacer auto;
        text-align: center;
    }
}

.view-static-news{

    line-height: 3rem;
    //font-size: 1.6rem;

    .intro{
        font-weight: 300;
    }

    h3{
        margin: 2*$spacer 0 $spacer;
    }
}