.result-set {
    border-radius: 0;
    border: none;
    background: #fff;
    box-shadow: $tmpltt_shadow;
    margin-bottom: $spacer * 3;


    .cNewDataset {
        float: right;
        height: 40px;
        width: 40px;
    }

    .linkTitel {
        cursor: pointer;

        &:hover {
            text-decoration: underline;
        }
    }

    //<section className="ResultSet cFixHight">
    &.cFixHight {
        //.card-content-wrapper und .card-accessibilty-icons untereinander anzeigen
        //da vorher flex gesetzt wurde
        flex-direction: column;

        .card-content-wrapper {
            //sorgt dafür, dass stretch (default) bei flex ausgeführt wird (Cards auf gleiche Höhe setzen)
            flex: 1;

            .eventinfo {
                @include make-col(10);
                @extend .d-flex;
                padding: 0 0 0 1rem;
                // flex-wrap:wrap;
                flex-direction: column;
                hyphens: auto;

                .eventdescription {
                    display: block;
                    display: -webkit-box;
                    -webkit-line-clamp: 4;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }

                .eventoffername {
                    height: 65px;
                    display: block;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }

            .eventmorebar {
                display: none;
            }
        }
    }

    .card-content-wrapper {
        @include pp(4);
        display: flex;
        flex-direction: column;

        .card-img-top {
            display: none;
        }

        .card-body {
            padding: 0;
        }

        .card-footer {
            background-color: transparent;
            border: none;
            padding: $spacer;
        }

        .event {
            @extend .d-flex;
            flex-wrap: nowrap;
        }

        .eventdatetime {
            @include make-col(2);
            @include pp(0, 1, 0, 0);

            .eventdate {
                order: 0;
                @include make-col(12);
                color: $tmpltt_font-color-results;

                span {
                    display: block;
                    font-size: 1.2rem;
                }

                .tag {
                    font-size: 3rem;
                    font-weight: 900;
                    margin-top: -5px;
                    margin-bottom: 7px;
                }

                .monat, .jahr {
                    margin-left: 3px;
                }
            }

            .eventtime {
                //display: none;
                order: 1;
                @include make-col(12);
                font-size: 1.2rem;
                line-height: 1.2em;
                color: $tmpltt_font-color-results;
                margin-top: 1rem;
                margin-left: 3px;
            }
        }

        .eventinfo {
            @include make-col(10);
            @extend .d-flex;
            padding: 0 0 0 1rem;
            // flex-wrap:wrap;
            flex-direction: column;
            hyphens: auto;

            .eventtopic {
                min-width: 100%;
                order: 1;

            }

            .eventoffername {
                min-width: 100%;
                order: 2;
                font-size: 2.4rem;
                font-weight: 600;
                // line-height: 1.2em;
                color: $tmpltt_font-color-results;
                hyphens: auto;
            }

            .eventorganame {
                min-width: 100%;
                order: 3;
                color: $tmpltt_font-color-results;
                margin-top: 1rem;

            }

            .eventdescription {
                min-width: 100%;
                order: 4;
                font-size: 1.3rem;
                line-height: 1.7rem;
                font-weight: 300;
                margin-top: 1rem;
                max-height: calc(1.7 * 15rem);
                overflow: hidden;
                position: relative;


                p:first-child {
                    font-weight: 600;
                    margin: 0;
                }

                p:last-child {
                }
            }

            .eventregion {
                min-width: 100%;
                order: 3;
                font-weight: 600;
                line-height: 1.2em;
                color: $tmpltt_font-color-results;
                margin-top: 1rem;

                //fix for weird bug in Chrome / Safari
                flex-basis: auto;
            }
        }

        .eventloc {
            flex: 0
        }

        .eventregion {
            @include make-col(12);
            @extend .d-flex;
            flex-wrap: nowrap;
            margin-top: 2rem;
            line-height: 1.2em;

            .eventmapbtn {
                order: 8;
                @include make-col(2);
                color: $tmpltt_font-color-results;
                cursor: pointer;

                img, svg {
                    height: 30px;
                    width: auto;
                }

                @include media-breakpoint-only(xs) {
                    position: relative;
                    left: -5px;
                }
            }

            .eventaddress {
                order: 9;
                @include make-col(10);
                padding: 0 0 0 0.25rem;
                font-size: 1.4rem;
                font-weight: 400;
                cursor: pointer;

                .title {
                    font-weight: 600;
                }

                p {
                    margin: 0;
                    color: $tmpltt_font-color-results;
                }
            }
        }

        .eventmorebar {
            order: 10;
            @include make-col(10);
            @include make-col-offset(2);

            .separator {
                margin: $spacer*2 0;
                // border-top:1px solid $tmpltt_font-color-results;
            }

            button {
                display: block;
                margin: 1.5rem auto 0;
                color: $tmpltt_font-color-results_contrast;
                background-color: transparent;
                border: 2px solid $tmpltt_font-color-results_contrast;
                padding: 0 2em;
                // border-radius: 2rem;
            }
        }
    }

    .card-accessibilty-icons {
        background-color: $tmpltt_background_accessibility;
        padding: 2rem;
        // @include mt(3);
        img, svg {
            height: 25px;
            width: auto;
            padding: 0 5px;
        }

        .icon_rollstuhl {
            border-right: 2px dotted #fff;
            padding-left: 0;
            padding-right: 10px;
            margin-right: 5px;
            margin-left: 10px;
        }

        span {
            display: inline-block;
            @include ml(2);
            color: $tmpltt_color_accessibility;
        }
    }

}


// FontResize
.result-set {
    &.fs-plus-1 {
        .eventorganame, .eventregion {
            font-size: 1.6rem !important;
        }

        .eventdescription {
            font-size: 1.4rem !important;
            line-height: 1.8rem !important;
        }

        .eventaddress {
            font-size: 1.5rem !important;
        }

        .eventdate {
            .monat, .jahr {
                font-size: 1.4rem !important;
            }
        }

        .eventtime {
            font-size: 1.4rem !important;
        }
    }

    &.fs-plus-2 {
        .eventorganame, .eventregion {
            font-size: 1.7rem !important;
        }

        .eventdescription {
            font-size: 1.5rem !important;
            line-height: 1.9rem !important;
        }

        .eventaddress {
            font-size: 1.6rem !important;
        }

        .eventdate {
            .monat, .jahr {
                font-size: 1.5rem !important;
            }
        }

        .eventtime {
            font-size: 1.5rem !important;
        }
    }

    &.fs-plus-3 {
        .eventorganame, .eventregion {
            font-size: 1.8rem !important;
        }

        .eventdescription {
            font-size: 1.6rem !important;
            line-height: 2.0rem !important;
        }

        .eventaddress {
            font-size: 1.7rem !important;
        }

        .eventdate {
            .monat, .jahr {
                font-size: 1.6rem !important;
            }
        }

        .eventtime {
            font-size: 1.6rem !important;
        }
    }
}
