.SearchFormEnhanced{

	z-index: 100;
	height: 40px;
	width: 100%;

	border-width:0;
	margin-top: 350px;

	.tab-content{
		height: 40px;
	}

	.title{
		display: none;
	}

	legend{
		display: none;
	}

	#searchContent{ 
		//background-color: white;
		max-width: 700px;
		margin: 0 auto;
	}

	.fieldsets-main{
		@extend .d-flex;
		@extend .flex-wrap;
		@include make-row(0);

		.option-select{

			.os__control{
				border-color: transparent;
				border-radius: 0;
				box-shadow: none;
				width:100%;
				&:hover{
					border-color: transparent
				}
				min-height:36px;
				margin-bottom:4px;
				// padding-right: 10px;
			}

			.os__placeholder{
				color: $tmpltt_font-color-searchmask;
			}

			.os__indicators{
				order: -1;
			}

			.os__clear-indicator{
				order: 2;
				border: none;
			}

			.os__value-container{
				padding-left: 0;
			}

			.os__indicator{
				padding: 4px;
				border-left: 1px solid $tmpltt_font-color-searchmask;
			}

			.os__indicator-separator{
				display: none;
			}

			.os__menu{
				background: grey;
			}

		}


		.fs-search-keywords{
			@include make-r-col(12,12,6,6,7);
			padding: 0;
			background-color: $trans-white;

			.keywords{
				display: inline-block;
				width: 100%;

				label{
					display: none;
				}
				input{
					border-radius:0;
					border-width:0px;
					padding: 5px 10px;
					height:3rem;
					margin: .5rem 1rem;
					margin-top: .5rem;
					margin-right: 1rem;
					background-color: transparent;
					width: 95%;
				}
				input::placeholder{
					color: $tmpltt_font-color-searchmask;
					font-weight: 200;
				}
			}


		}

		.fs-wrapper{
			@include make-r-col(12,12,6,6,5);
			padding: 0;

			.submit{
				display: block;
				.submit-label{
					display: none;
				}
				.submit-button{
					border: none;
					background: none;
					font-weight: 200;
					color:$tmpltt_font-color-searchmask;
					margin-bottom: 0;
					margin-top: .7rem;
				}
			}

			height: 40px;
			display: flex;

			justify-content: space-evenly;
			@include media-breakpoint-up(md) {
				justify-content: center;
			}

			background: $tmpltt_bg-color-searchmask;
			background-clip: content-box;
		}

		.fs-search-timespan{
			//@include media-breakpoint-up(md) {
			//	border-left:1px solid $tmpltt_font-color-searchmask;
			//}
			height: 28px;
			//margin-top: 0.5rem;

			.fa{
				position: relative;
				top: 1px;
				color:$tmpltt_font-color-searchmask;
			}

			.preselect{
				display:none;
			}

			//customize daterange-picker
			.react-daterange-picker__wrapper{
				border: none;
			}
			.react-daterange-picker__range-divider{
				display: none;
			}
			.react-daterange-picker__clear-button{
				display: none;
			}
			.react-daterange-picker__inputGroup{
				display: none;
			}

		}

		.search-extend-controls{
			.extend-button{
				button{
					z-index:10;
					font-size: 1.4rem;
					color:$tmpltt_font-color-searchmask;
					margin-top: 0.5rem;
					height: 3rem;
				}
			}
		}

		.fs-search-town{
			ul{
				list-style: none;
				padding: 0;
			}
			button:not(.dropdown-item){
				font-size: 1.4rem;
				background-color:transparent;
				margin-top: .5rem;
				color:$tmpltt_font-color-searchmask;
				border: none;
				//border-left:1px solid $tmpltt_font-color-searchmask;

			}
			.dropdown-toggle::after{
				display:none;
			}
			.dropdown-toggle::before{
				@extend .fa;
				content:" \f078";
				margin-right:.5em;
				display:inline;
				width:auto;
			}
			.dropdown-menu{
				background: $tmpltt_bg-color-searchmask;
				border: none;
			}
		}

		.fs-search-extend-controls{
			// @include make-r-col(4,4,2);
			// background: yellow;

			.extend-button button{
				font-size: 1.4rem;
				border:none;
				margin-top: 0.5rem;
				padding-top: 0.5rem;
				color: $tmpltt_font-color-searchmask;
				//border-left:1px solid $tmpltt_font-color-searchmask;

				border-radius: 0rem;
			}

		 	button.btn.collapsed:before{
				@extend .fa;
				content:" \f078";
				margin-right:.5em;
				display:inline;
				width:auto;
			}
 			button.btn:before{
				@extend .fa;
				content:" \f077";
				margin-right:.5em;
				display:inline;
				width:auto;
			}
		}
	}



	#fieldsets-extended{

		padding: $spacer $spacer*2;
		background: $tmpltt_bg-color-searchmask-extended;

		.option-select{
			// background: green;

			.os__control{
				// display: none;
				border-color: transparent;
				border-radius: 0;
				box-shadow: none;
				width:100%;
				background: $white;
				&:hover{
					border-color: transparent
				}
				min-height:36px;
				margin-bottom:4px;
			}

			.os__indicator{
				padding: 4px;
			}

			.os__option--is-focused{
				background: $tmpltt_bg-color-searchmask;
			}

		}

		.fieldset-verortung{
			display:none;
		}

		.search-location{


			.title{
				display: block;
			}

			label{display: none;}

			fieldset{
				margin: 0.5rem 1rem;
				padding: 1rem;
				background: $white;
			}

			.input-group{
				font-size: 1.3rem;
				font-weight: 400;

				.custom-select, input{
					font-size: 1.3rem;
					color: $tmpltt_font-color-content;
					background-color: $tmpltt_bg-color-searchmask-extended;

					border: none;
					border-bottom: 2px solid $tmpltt_font-color-content;
				}
				// input{padding:0 1rem; }
				input::placeholder{color:$tmpltt_font-color-content;}
			}
		}

		.search-constrict{
			margin-top:2rem;
			.os__placeholder{
				color: $tmpltt_font-color-content;
			}
			.form-check-label{
				@include pp(0,0,0,1);
			}
			.title{
				display: block !important;
				position: inherit;
				border-bottom: 2px solid $tmpltt_font-color-content;
			}
		}

		.fieldset-datatype{
			display: none;
		}

		.detailsearch{
			@include mm(1,0);
			@include pp(1,3);
			font-size: 1.3rem;
			font-weight: 300;
			background-color: transparent;
			border-radius: 0;
			border:none;

			.option-select{
				border-bottom: 1px solid $tmpltt_font-color-content;
			}

			.os__indicator-separator{
				display: none;
			}

			a{
				color: $tmpltt_font-color-results-linktext;
			}
			.expander{
				display:none;
				&:after{
					padding-left: 2rem;
					@extend .fa;
					//content:" \f077"
				}
			}
			.collapsed .expander{

				//&:after{content:" \f078"}
			}
		}

		.fieldset-controls{

			@include mm(3,0,0);

			display: inherit;

			button {
				background-color: $st-yellow;
				border: none;
				color: $tmpltt_font-color-content;
				font-size: 1.3rem;
				padding: $spacer 2*$spacer;
				margin-bottom: 2rem;
				margin-top:1rem;
			}

			.button-reset{
				float:left;
			}
			.button-submit{
				float: right;
			}

			.desc.help{
				display: none;
			}

		}
	}

}
