.search-list-box {
    color: inherit;

    h2{
        color: inherit;
    }

    span.link {
        display: block;
        cursor: pointer;
        text-decoration: underline;
    }
}