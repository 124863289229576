/*******************************************
     Footer
*******************************************/

footer{
	background: $white;

	h3{
		font-size:1.3rem;
		white-space: nowrap;
	}

	.img-wrapper{
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	section{
		@include media-breakpoint-up(sm) {
		}
	}

	.row-1{
		border-top: 2px solid $mine-shaft-bright;
		padding-bottom: 2*$spacer;

		.col-left{
			background-color: $tmpltt_layout_color-footer-box-left;
			@include make-r-col(12, 12, 9);
			padding-top: $spacer * 2;
			.img-wrapper{
				padding-right: $spacer;
				margin-top: -2rem;
			}
		}
		.col-right{
			background-color: $tmpltt_layout_color-footer-box-right;
			@include make-r-col(12, 12, 3);
			border-left: 1px solid $mine-shaft-bright;
			margin-top: $spacer * 2;
			padding-left: 2*$spacer;
		}
		img{
			max-height: 7rem;
		}
		img.img-fruehe-hilfen{
			max-height: 10rem;
		}
	}

	.row-2{
		padding-bottom: 4*$spacer;

		.gebit{
			border-top: 1px solid $mine-shaft-bright;
			display: flex;
			@include make-r-col(12);
			padding: 2*$spacer;
			background:#fff;

			p{
				max-width: 500px;
				color: $tmpltt_font-color-content;
				font-weight: 300;
				font-size: 1.2rem;
			}

			@include media-breakpoint-up(sm) {
				img{
					@include mm(0, 5);
				}
				p{
					@include mm(0, 5);
				}
			}
		}
	}

	.ruler{
		border-top: 1px solid $tmpltt_layout_color-footer-ruler;
	}

	.section-legalcopy{
		display: block;
		padding:2rem 2rem 1rem;
		.title{
			display: none;
		}
		text-align: center;
		font-size: 1.6rem;
		font-weight: 300;
		background: $tmpltt_layout_color-footer;
		color:white;
		a{color:white;}
	}

	//TMP: bitte beachten, kann überarbeitet werden, nur Temporär wegen DH eingefügt
	.kommunen{
		.img-fluid{
			max-height: 90px;
			@include pl(20px);
		}
	}
}
