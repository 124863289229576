header.site-header {

  min-height: 600px;
  background-size: cover;
  background-position: center;
  padding-top: 2*$spacer;

  position: relative;
  z-index: 100;

  .site-opening {
    @include make-r-col(11, 11, 8);

    .logocol {
      @extend .d-flex;

      .logo {
        @include mm(10px);
        //@include make-col(12);

        a {
          position: relative;
          display: block;
          width:230px;
          height: 100px;
          background-repeat: no-repeat;
          background-size: contain;
          background-position: left;

          cursor: pointer;

          h1 {
            color: $tmpltt_font-color-content;
            font-weight: 900;

            font-size: 35px;
            line-height: 35px;
            position: relative;
            top: 24px;

            @include media-breakpoint-only(xs) {
              font-size: 26px;
              line-height: 26px;
            }


            small {
              font-weight: 500;
            }
          }
        }
      }
    }
  }

  //Extra Function Menu (Drucken, teilen usw.)
  .site-menus {
    @include make-r-col(1, 1, 4);
    @include pp(0, 2);

    .desktopfunctionscol {
      display: flex;
      @include media-breakpoint-down(sm) {
        display: none;
      }
      font-size: 1.3rem;


      white-space: nowrap;
      color: $tmpltt_font-color-searchmask;

      button {
        height: 16px;
        width: 16px;
        background: transparent;
        color: $tmpltt_font-color-menufunctions;
        font-size: 10px;
        line-height: 12px;
        padding: 0;
        margin: $spacer 0.2*$spacer;
        border: 0;
      }

      //hide backend login //ToDo: wollen wir das überhaupt?
      .fa-lock {
        display: none;
      }

      .dropdown-menu {
        border: none;
        background: transparent;
        margin-top: -$spacer;

        button {
          margin-right: 2*$spacer;
        }
      }

      svg {
        width: 28px;
        margin-right: 1rem;
        filter: grayscale(1); //ToDo scss: ist es eine Variable?
      }

      svg:hover, svg:active {
        filter: none;
      }
    }

    .mainmenucol {
      .navbar-toggler {
        margin-top: 3rem;
        color: $tmpltt_font-color-menuburger;
        @include media-breakpoint-up(md) {
          margin-top: 0;
        }
        font-size: 2rem;
      }

      .text {
        @include media-breakpoint-down(sm) {
          display: none;
        }
        text-align: right;
        padding: 2rem 2rem 0;

        a:link, a:visited {
          font-weight: 600;
          color: black;
        }
      }
    }

  }

  //Menu
  .menuwrapper {
    position: relative;
    z-index: 1000;
    margin: 0 -15px;

    .menurow {
      position: absolute;
      @include menu-r(12, 12, 6, 4, 3);

      @include pp(2, 3, 5);
      background-color: $tmpltt_layout_color-menu;

      ul {
        list-style: none;
        padding: 0;
        margin: 0;
      }

      h4, p, a:link, a:visited {
        color: $tmpltt_font-color-menu;
      }

    }

    .menucloser {
      color: $tmpltt_font-color-menu;
      text-align: right;
    }

    .menucol {
      .title {
        display: none;
      }
    }

    .menuitems {
      li {
        padding-left: $spacer*2;
        font-size: 1.5rem;
        font-weight: 500;

        &.main_links, &.links_title {
          font-weight: 900;
          font-size: 1.8rem;
          padding-top: $spacer*2;
          color: $tmpltt_font-color-menu;
        }
      }
    }
  }

}
