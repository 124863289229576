.ContrastChanger{
	margin-right: 65px;
	white-space: nowrap;
	
	button{
		font-size: 1.3rem !important;
	}

	i{
		margin-right: 1rem;
	}

}