// siehe SearchDetails.scss
.search-offers-from-provider {
    background-color: $tmpltt_layout_color-general;
    // padding-top:
    @include py($spacer*2);
    //top, right; bottom; left,
    @include mm(0, -$spacer*3, -$spacer*3, -$spacer*3);

    .create-results-header {
        background-color: $tmpltt_layout_color-highlight;
        @include mx($spacer);
        //margin-bottom: $spacer;
        .title {
            font-size: 2rem;
            color: $tmpltt_font-color-results_contrast;
            @include pp($spacer);
        }
    }

    .result-sets-row {
        @include make-row();
        @include py(3);
        @include mx(1);

        .result-set {
            @include make-r-col(12, 12, 6, 6, 6);
        }
    }

    .result-set {
        display: flex;
        @include mb($grid-gutter-width);
        background-color: $tmpltt_layout_color-general;
        box-shadow: none;

        .card-content-wrapper {
            background-color: $tmpltt_layout_color-partial;
        }
    }

}
